<template>
  <v-menu
    offset-x
    :close-on-content-click="false"
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        :disabled="disabled"
      >
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
    </template>
    <v-date-picker
      v-model="selectedDate"
      :header-color="primaryColor"
      :color="primaryColor"
      style="height: 400px"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
export default {
	name: "DateSelectorButton",
	props: {
		disabled: {
			type: Boolean,
		},
		date: {
			type: Date,
			default: new Date()
		}
	},
	data() {
		return {
			menu: false
		}
	},
	computed: {
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		selectedDate: {
			get() {
				let date = this.date.toISOString()
				return date.substr(0, 10)
			},
			set(date) {
				let dateArr = date.split('-')
				this.$emit('newDate', new Date(dateArr[0], dateArr[1]-1, dateArr[2]))
			}
		}
	}
}
</script>

<style scoped>

</style>